import axios from 'axios'

const apiClient = axios.create({
  baseURL: 'https://web-api-sgp.piebarometre.digital/',
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + localStorage.getItem('token'),
  },
})
export default {

  getSynthesegraphData () {
    return apiClient.get('api/request-parametre-intervention/globale-graphe')
  },
  getSyntheseTableauData () {
    return apiClient.get('api/request-parametre-intervention/globale-tableaux')
  },
}
