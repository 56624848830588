<template>
  <v-container
    fluid
    class="down-top-padding"
  >
    <apexchart
      v-for="data in chartData"
      :key="data[0].options.chart.id"
      class="wrap"
      height="650"
      :options="data[0].options"
      :series="data[0].series"
    />
  </v-container>
</template>

<script >
  import sytheses from '@/services/syntheses.js'
  export default {
    data () {
      return {
        dataset: [],
        chartData: [],
        options: {
          chart: {
            id: 'vuechart-example',
          },
          title: {
            text: 'Test',
            align: 'Center',
          },
          xaxis: {
            tye: 'categories',
            // categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
          },
        },
        series: [{
          name: 'series-1',
          data: [
            {
              y: 30,
              x: 1991,
            },
            {
              y: 40,
              x: 1992,
            },
            // 30, 40, 45, 50, 49, 60, 70, 91
          ],
        }],
      }
    },
    mounted () {
      sytheses.getSynthesegraphData().then(response => {
        this.dataset = response.data.datasets
        /* this.dataset.foreach((element, index) => {
          console.log(element)
          console.log(index)
        }) */
        var i = 0
        this.chartData = Object.keys(this.dataset).map((element) => {
          return [
            {
              options: {
                chart: {
                  id: 'vuechart-example-' + i++,
                  type: 'bar',
                  height: 650,
                },
                plotOptions: {
                  bar: {
                    horizontal: false,
                    columnWidth: '15%',
                    endingShape: 'rounded',
                  },
                },
                dataLabels: {
                  enabled: false,
                },
                stroke: {
                  show: true,
                  width: 2,
                  colors: ['transparent'],
                },
                title: {
                  text: element,
                  align: 'Center',
                },
                xaxis: {
                  tye: 'categories',
                  // categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
                },
              },
              series: [
                {
                  name: 'series-' + i++,
                  data: this.dataset[element].map((element) => {
                    return {
                      x: element.x,
                      y: element.y,
                    }
                  }),
                },
              ],
            },

          ]
        })
        console.log(this.chartData)
      }).catch((err) => {
        console.log(err.data)
      })
    },
    method: {
      getseries (data) {

      },
    },
  }
</script>

<style lang="scss" scoped>

</style>
